/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {  Link, animateScroll as scroll } from "react-scroll";
import SubNavbar from './sub-navbar';

const Navbar = ({setLang, wording, wordingFullarray}) => {
  const language = useSelector((state) => state.language);
  const [countscroll, setCountscroll] = useState(0);
  const [logo, setLogo] = useState({
		toko: '/assets/logo_white.png',
		tko: '/assets/tko-putih.svg',
  });
  const [burger, setBurger] = useState('/assets/icon/burger.svg');
  const [langheh, setLangheh] = useState({
		id: '/assets/icon/id.svg',
		en: '/assets/icon/en.svg',
  });
  const [classNav, setClassNav] = useState('navbar-custom');
//   const [clickedOn, setClicked] = useState(false);
  const activeBurger = () => {
    if (burger === '/assets/icon/burger-active.svg' && countscroll === 0) {
			const inlogo = {
				toko: '/assets/logo_white.png',
				tko: '/assets/tko-putih.svg',
			};
			setLogo(inlogo);
			setBurger('/assets/icon/burger.svg');
			setClassNav('navbar-custom');
    }else{
			const inlogo = {
				toko: '/assets/logo_colourful.png',
				tko: '/assets/tko-color.svg',
			};
			setLogo(inlogo);
			setBurger('/assets/icon/burger-active.svg');
			setClassNav('navbar-custom-active');
			setTimeout(() => {
				document.getElementById("navbarSupportedContent").style.height = "100vh";
			}, 500);
    }
  }
  const scrollFunction = () => {
    const mybutton = document.getElementById('myBtn');
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = 'block';
    } else {
			mybutton.style.display = 'none';
    }
  };
  const gotoTop = () => {
    scroll.scrollToTop();
  };
  const[sebelumScroll,setSebelumScroll] = useState(0);
  useEffect(() => {
		window.onscroll = function(element) {
			setSebelumScroll(countscroll);
			const setNavbar =  document.getElementById('navbar');
			const setkalian = document.body.getBoundingClientRect().top === 0 ? 0 : -1*document.body.getBoundingClientRect().top;
			// if (countscroll > setkalian) {  
			//     console.log('live',setkalian);
			//     console.log('onscroll',setkalian);
			//     // setNavbar.style.paddingTop = "0px";
			// }else{
			//     setNavbar.style.paddingTop = "0px";

			// }
			setCountscroll(setkalian);
			scrollFunction();
    };
  }, []);
	const isCollapse = () => {
		return document.getElementById('buttonNavbarSupportedContent').getAttribute('aria-expanded')
	}

	const setNavbarMobile = (isActive) => {
		if(isActive) {
			const inlogo = {
				toko: '/assets/logo_colourful.png',
				tko: '/assets/tko-color.svg',
			};
			setLogo(inlogo);
			setBurger('/assets/icon/burger-active.svg');
			setClassNav('navbar-custom-active');
		}
		else {
			const inlogo = {
				toko: '/assets/logo_white.png',
				tko: '/assets/tko-putih.svg',
			};
			setLogo(inlogo);
			setBurger('/assets/icon/burger.svg');
			setClassNav('navbar-custom');
		}
	}

  useEffect(() => {		
		if (countscroll > 10) setNavbarMobile(true) 
		else setNavbarMobile(isCollapse() === "true" ? true : false)
		
  }, [countscroll]);
	
  const hideBar = () => {
		if(countscroll < 10) setNavbarMobile(false)
		else setNavbarMobile(true)
		document.getElementById('navbarSupportedContent').classList.remove('show');
		document.getElementById('buttonNavbarSupportedContent').setAttribute('aria-expanded', 'false');
  }
  const changeLang = (val) => {
    setLang(val);
    hideBar();
  }
  return (
    <>
			<nav className={`navbar navbar-expand-lg fixed-top ${classNav}`} id="navbar">
				<div className="navbar-brand">
					<a href="#">
						<img src={logo.toko} alt="logo" />
					</a>
					<a href="#">
						<img src={logo.tko} alt="tko" />
					</a>
				</div>
				<button id="buttonNavbarSupportedContent" onClick={() => activeBurger()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<img src={burger} alt="burger" />
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav mr-auto mobile-munculeh">
						<li className="nav-item active">
							<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(118).Notes}>
								{wording(118)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(119).Notes}>
								{wording(119)}
							</Link>
						</li>
						<SubNavbar 
							title={wording(120)}
							childrens={[
								{ isRedirect: true, title: wordingFullarray(128)[language.toUpperCase()], href: wordingFullarray(128).Notes, target:"_blank", rel:"noreferrer" },
								{ isRedirect: true, title: wordingFullarray(129)[language.toUpperCase()], href: wordingFullarray(129).Notes, target:"_blank", rel:"noreferrer" }
							]}
						/>
						{/* <li className="nav-item">
								<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(120).Notes}>
										{wording(120)}
								</Link>
						</li>  */}
						<li className="nav-item">
							<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(121).Notes}>
								{wording(121)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(122).Notes}>
								{wording(122)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(123).Notes}>
								{wording(123)}
							</Link>
						</li> 
						<li className="nav-item">
							<a className="nav-link sosmed-nav">
								<span>{wording(124)}</span>
								<div className="group-icon">
									<a href={wordingFullarray(110).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/linkedin.svg" className="heh-sosmed" alt="sosmed icon" />
									</a>
									<a href={wordingFullarray(111).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/fb.svg" className="heh-sosmed" alt="sosmed icon" />
									</a>
									<a href={wordingFullarray(112).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/twitheh.svg" className="heh-sosmed" alt="sosmed icon" />
									</a>
									<a href={wordingFullarray(113).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/ig.svg" className="heh-sosmed" alt="sosmed icon" />
									</a>
									<a href={wordingFullarray(103).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/teleitem.svg" className="icon-sosmed" alt="sosmed footer" />
									</a>
									{/* <a href={wordingFullarray(103).Notes} target="_blank" rel="noreferrer">
										<img src="/assets/icon/tele.png" height="20" width="20" className="icon-sosmed" alt="sosmed footer" />
									</a> */}
								</div>
							</a>
						</li> 
						<li className="nav-item">
							<a className="nav-link nav-langheh">
								<div className="lang-heh" onClick={() => changeLang('en')}>
									<img src={langheh['en']} alt="lang" />
									<span>English</span>
								</div>
								<div className="lang-heh" onClick={() => changeLang('id')}>
									<img src={langheh['id']} alt="lang" />
									<span>Indonesia</span>
								</div>
							</a>
						</li> 
					</ul>
					<ul className="navbar-nav my-2 my-lg-0 nav-horizontal hilangpasmobile">
						<li className="nav-item active">
							<Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(118).Notes}>
								{wording(118)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(119).Notes}>
								{wording(119)}
							</Link>
						</li> 
						<li className="nav-item">
							{/* <Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(120).Notes}>
								{wording(120)}
							</Link> */}
							<div className="nav-item nav-link nav-tko-top-link">
								<span className="dropdown-toggle nav-tko-dropdown" id="TKOUtilityNavbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{wording(120)}
								</span>
								<div className="dropdown-menu dropdown-menu-left-auto" aria-labelledby="TKOUtilityNavbarDropdown">
									<a href={wordingFullarray(128).Notes} target="_blank" rel="noreferrer" className="dropdown-item">{wordingFullarray(128)[language.toUpperCase()]}</a>
									<a href={wordingFullarray(129).Notes} target="_blank" rel="noreferrer" className="dropdown-item">{wordingFullarray(129)[language.toUpperCase()]}</a>
								</div>
							</div>
						</li> 
						<li className="nav-item">
							<Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(121).Notes}>
								{wording(121)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(122).Notes}>
								{wording(122)}
							</Link>
						</li> 
						<li className="nav-item">
							<Link className="nav-link nav-tko-top-link" onClick={() => hideBar()} spy={true} smooth={true} offset={-70} duration={700} to={wordingFullarray(132).Notes}>
								{wording(132)}
							</Link>
						</li> 
					</ul>
					<ul className="navbar-nav my-2 my-lg-0 hilangpasmobile">
						<li className="my-2 my-lg-0 nav-item dropdown">
							<a className="nav-link dropdown-toggle nav-tko-drop-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src={langheh[language]} alt="lang" />
								<span>{language}</span>
							</a>
							<div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
								<a className="dropdown-item" onClick={() => setLang('id')}>Indonesia</a>
								<a className="dropdown-item" onClick={() => setLang('en')}>English</a>
							</div>
						</li>
					</ul>
				</div>
			</nav>
			<button onClick={() => gotoTop()} type="button" id="myBtn" title="Go to top">
				<img alt="top" src="/assets/icon/gotop.svg" />
			</button>
    </>
  );
};
export default Navbar;
